import i18next from '@public/js/startup-tasks/i18next';

const getDefaultState = () => ({
    pageName: 'default',
    pageClasses: [],
    modalName: null,
    rapidTemplate: true && "a",
    useRecaptchaV2: false,
    useRecaptchaV3: true,
});

const state = getDefaultState();

const getters = {
    // convenience getter for elements that need to know when modals are open/closed
    // such as adding dynamic attributes to help create modal locks (inert, aria-hidden, etc).
    // ex: :aria-hidden="String(this.$store.getters['ui/isModalOpen'])" vs
    // :aria-hidden="String(!!this.$store.state.ui.modalName)"
    isModalOpen: (state) => !!state.modalName,
};

const { body } = document;
const LOADING_CLASS = 'page-loading';

const mutations = {
    pageEnter (state, newPage) {
        if (state.pageClasses && state.pageClasses.length) {
            body.classList.remove(...state.pageClasses);
        }

        state.pageName = newPage.pageName || newPage.$options.name;
        state.pageClasses = newPage.pageClasses;

        body.id = state.pageName;
        const titleMap = {
            'dup-account': 'Duplicate Account',
            'error': 'Error',
            'expired': 'Too Late',
            'faq': 'FAQ',
            'game': 'Game',
            'ineligible-age': 'Ineligible',
            'inky': 'Congrats',
            'janky': 'Sorry',
            'limiter': 'Limited',
            'login': 'Login',
            'maintenance': 'Maintenance',
            'poll': 'Poll',
            'prelaunch': 'Begins Soon',
            'register': 'Register',
            'viral': 'Friends',
            'viral-thanks': 'Thanks',
            'viral-no-thanks': 'Thanks',
        };
        if (titleMap[state.pageName]) {
            document.title = titleMap[state.pageName] + ' | Regions Bank “Thanks, Neighbor” Sweepstakes';
        }
        else {
            document.title = 'Regions Bank “Thanks, Neighbor” Sweepstakes';
        }

        if (body.classList.contains(LOADING_CLASS)) {
            body.classList.remove(LOADING_CLASS);
        }
        if (state.pageClasses && state.pageClasses.length) {
            body.classList.add(...state.pageClasses);
        }
    },
    pageLeave (state) {
        body.classList.add(LOADING_CLASS);
    },

    modalOpen (state, newModal) {
        state.modalName = newModal.modalName;
    },
    modalClose (state) {
        state.modalName = null;
    },
};

const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
