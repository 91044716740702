
    import BaseModal from './BaseModal';

    export default {
        name: 'ErrorModal',

        extends: BaseModal,

        props: {
            // Note: if you pull these values from copydeck, you need to make
            // sure the namespace is loaded before we need this component, ideally at
            // app startup, since the user may not have a viable Internet connection
            // when this modal appears.
            headline: {
                type: String,
                default: 'Something went wrong.',
            },
            body: {
                type: String,
                default: 'Please try to refresh the page to get back on track.',
            },
        },

        data () {
            return {
                modalName: 'error',
            };
        },
    };
