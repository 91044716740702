
    import BaseModal from './BaseModal';

    export default {
        name: 'RegLoginConfirmationModal',

        extends: BaseModal,

        props: {
            // Note: if you pull these values from copydeck, you need to make
            // sure the namespace is loaded before we need this component, ideally at
            // app startup, since the user may not have a viable Internet connection
            // when this modal appears.
            headline: {
                type: String,
                default: 'Great!',
            },
            body: {
                type: String,
                default: 'You just earned a sweeps entry towards the grand prize.',
            },
        },

        data () {
            return {
                modalName: 'regLoginConfirmationModal',
            };
        },
    };
