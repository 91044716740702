// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    GamePage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/GamePage.vue'
    ),
    InkyPage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/InkyPage.vue'
    ),
    JankyPage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/JankyPage.vue'
    ),
    PollPage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/PollPage.vue'
    );

export default [
    { path: '/poll',  component: PollPage  },
    { path: '/game',  component: GamePage  },
    { path: '/inky',  component: InkyPage  },
    { path: '/janky', component: JankyPage },
];
