
import axios from 'axios';
import { reactive, set } from 'vue';

import api from '../../api';

const getDefaultState = () => reactive({
    states: [],
});

const state = getDefaultState();

const getters = {};

const mutations = {
    updateState (state, data) {
        for (const key in data) {
            set(state, key, data[key]);
        }
    },
};

const actions = {
    async loadStates ({ dispatch, commit }) {
        try {
            const response = await axios.get(`${api.base}/eligible-states`);
            commit('updateState', response.data);
            return response.data.states;
        }
        catch (err) {
            console.error(
                'error making eligible-states call',
                err.message,
                err,
            );

            return;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
